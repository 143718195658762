export const POST_MESSAGES_TYPES = {
  CALL: "call",
  COMPLETED_LOCATION: "completedLocation",
  NAVIGATION: "navigation",
  SEND_MESSAGE: "sendMessage",
  SEND_MESSAGE_FOR_DIRECT: "sendMessageForDirect",
  MAP: "map",
  ROUTE: "route",
  TOAST_MESSAGE: "toastMessage",
};

/**
 * 목록 페이지로 이동(배송 목록)
 */
export const goToDeliveries = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: POST_MESSAGES_TYPES.NAVIGATION,
        to: "deliveries",
      }),
    );
  } else {
    window.alert("페이지 이동에 실패했습니다. 앱 종료후 다시 시도해주세요");
  }
};

/**
 * 목록 페이지로 이동(픽업 목록)
 */
export const goToPickups = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: POST_MESSAGES_TYPES.NAVIGATION,
        to: "pickups",
      }),
    );
  } else {
    window.alert("페이지 이동에 실패했습니다. 앱 종료후 다시 시도해주세요");
  }
};

/**
 * 문자 발송
 */
export const sendSmsMessage = ({ mobile, message }) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: POST_MESSAGES_TYPES.SEND_MESSAGE,
        mobile,
        message,
      }),
    );
  } else {
    window.alert("문자 발송에 실패했습니다. 다시 시도해주세요");
  }
};

/**
 * 문자 다이렉트 발송
 */
export const sendSmsMessageForDirect = ({ mobile, message }) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: POST_MESSAGES_TYPES.SEND_MESSAGE_FOR_DIRECT,
        mobile,
        message,
      }),
    );
  } else {
    window.alert("문자 발송에 실패했습니다. 다시 시도해주세요");
  }
};

/**
 * Toast Message 발송
 */
export const sendToastMessage = (message) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: POST_MESSAGES_TYPES.TOAST_MESSAGE,
        message,
      }),
    );
  } else {
    window.alert(message);
  }
};
